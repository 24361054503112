import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { gameServerApi } from "services/gameServerV2/";
import WarnignIcon from "Components/Icons/WarningIcon.svg";

import {
    formatInviteMemberRows,
    initInviteMemberData,
    formatInviteMembership,
    formatDeleteMemberData,
    formatDeleteMultiMemberData,
} from "./formatData";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import SuccessInvite from "Components/Icons/SuccessInvite.svg";
import { handleError } from "./handleError";
import { CONSTANTS } from "consts";
import { useWindowSize } from "scripts/useWindowSize";

interface IMembershipTable {
    dataTable: {
        column: Array<{ title: string; key: string; className?: string }>;
        rows: Array<any>;
        actions?: Array<{ name: string; action: any }>;
        deleteMultiAction?: any;
        loading?: boolean;
        pagination?: {
            totalRows: number;
            rowsPerPage: number;
            handleChangePage: any;
            currentPage: number;
        };
    };
}

export const useMembershipHook = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [emptyPage, setEmptyPage] = useState<boolean>(true);
    const [invitedMembership, setInvitedMembership] =
        useState<any>(initInviteMemberData);
    const { id: membershipId } = useParams() as any;

    const PER_PAGE = 5;
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchParam = searchParams.get("search") || "";
    const pageParam = searchParams.get("page") || 1;
    const perPageParam = searchParams.get("perPage") || PER_PAGE;
    const [queryParameter, setQueryParameter] = useState<{
        search: string;
        page: number;
        perPage: number;
    }>({
        search: searchParam || "",
        page: Number(pageParam),
        perPage: Number(perPageParam),
    });
    const [currentMembership, setCurrentMembership] = useState<any>(null);
    const limitUserInvite = currentMembership?.constrainTypes?.max_family_user || 0;
    const totalUserInvite = invitedMembership?.total || 0;
    const column = [
        {
            title: "",
            key: "selection",
        },
        {
            title:
                "User" +
                ((limitUserInvite && limitUserInvite)
                    ? ` (${totalUserInvite}/${limitUserInvite})`
                    : ""),
            key: "user",
            className: "userHeaderCol",
        },
        {
            title: "Role",
            key: "role",
            className: "roleHeaderCol",
        },
        {
            title: "Status",
            key: "status",
            className: "statusHeaderCol",
        },
    ];
    const columnMobile = [
        {
            title: "",
            key: "selection",
        },
        {
            title:
                "User" +
                ((limitUserInvite && limitUserInvite)
                    ? ` (${totalUserInvite}/${limitUserInvite})`
                    : ""),
            key: "user",
            className: "userHeaderCol",
        },
        {
            title: "",
            key: "role",
            className: "roleHeaderCol",
        },
        {
            title: "",
            key: "status",
            className: "statusHeaderCol",
        },
    ];

    const [columnTable, setColumnTable] = useState(column);
    const windowSize = useWindowSize();
    useEffect(()=>{
        // if(limitUserInvite > 0 && totalUserInvite > 0) {
            if(windowSize?.width) {
                if(windowSize?.width >= 900) {
                    setColumnTable(column)
                } else {
                    setColumnTable(columnMobile)
                }
            // }
        }
    }, [windowSize, limitUserInvite, totalUserInvite])

    const getMembershipDetail = async (
        orgId: string = membershipId,
        search: string = ""
    ) => {
        setLoading(true);
        // Get membership familly data
        gameServerApi.membership
            .getMembershipFamilly({
                orgId,
                search,
            })
            .then((res: any) => {
                setLoading(false);
                setCurrentMembership(res?.data?.currentMembership);
            })
            .catch((err) => {
                setLoading(false);
                console.log("errrmlmlmml", err);
            });
    };

    const resetMemberList = async () => {
        await getMembershipDetail();
        setQueryParameter({ ...queryParameter, page: 1, perPage: PER_PAGE });
    };

    const getInvitedMembership = (
        orgId = membershipId,
        search = "",
        pagination = { page: 1, perPage: PER_PAGE }
    ) => {
        setLoading(true);
        // Get membership familly data
        gameServerApi.membership
            .getMembershipInviteList({
                orgId,
                search,
                pagination,
            })
            .then((res: any) => {
                setLoading(false);
                setEmptyPage(false);
                setInvitedMembership(formatInviteMembership(res?.data));
            })
            .catch((err) => {
                const errorRes = handleError(err.response);
                if (errorRes === "error_permission") {
                    window.location.href = CONSTANTS.NAVIGATION_URL.NOT_FOUND;
                } else {
                    setEmptyPage(false);
                    setLoading(false);
                }
            });
    };

    const handleSearch = (newValue: any) => {
        setQueryParameter({ ...queryParameter, search: newValue, page: 1 });
    };

    const handleChangePage = (newPage: number) => {
        setQueryParameter({ ...queryParameter, page: Number(newPage) });
    };

    const deleteMember = async (members: Array<string>) => {
        if (members?.length > 0) {
            const result = await Swal.fire({
                title: '<div>ARE YOU SURE YOU WANT TO REMOVE?<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: `<div style="color: #161616;font-size: 16px;">This person will no longer be able to use the services included in your membership package.</div>`,
                showCancelButton: false,
                confirmButtonText: "REMOVE",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }
            const userIds = formatDeleteMemberData(members);
            setLoading(true);
            try {
                await gameServerApi.membership.deleteMembership({
                    orgId: membershipId,
                    deleteValue: [...userIds],
                });
                setLoading(false);
                await Swal.fire({
                    title: "Success",
                    html: `<div style="padding: 0 16px;">The member has been removed. You can invite them back at any time.</div>`,
                    imageUrl: SuccessInvite,
                    showClass: {
                        popup: `
                                error-invite-member-popup
                            `,
                    },
                });
                await resetMemberList();
            } catch (error) {
                setLoading(false);
                setTimeout(() => {
                    Swal.fire("Error", `Delete Membership Error! `, "error");
                }, 600);
            }
        }
    };

    const deleteMultiMember = async (members: Array<string>) => {
        if (members?.length > 0) {
            const userIds = formatDeleteMultiMemberData(members);
            const subTitleConfirmText =
                userIds.length > 1
                    ? `<div>By deleting these members, they will no longer be able to use the services included in your membership package.</div>`
                    : `This person will no longer be able to use the services included in your membership package.`;
            // console.log({members, userIds})
            const result = await Swal.fire({
                title: '<div>ARE YOU SURE YOU WANT TO REMOVE?<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: subTitleConfirmText,
                showCancelButton: false,
                confirmButtonText: "REMOVE",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }
            setLoading(true);
            try {
                await gameServerApi.membership.deleteMembership({
                    orgId: membershipId,
                    deleteValue: [...userIds],
                });
                setLoading(false);
                await Swal.fire({
                    title: "Success",
                    html: `<div style="padding: 0 16px;">The member has been removed. You can invite them back at any time.</div>`,
                    imageUrl: SuccessInvite,
                    showClass: {
                        popup: `
                                error-invite-member-popup
                            `,
                    },
                });
                await resetMemberList();
            } catch (error) {
                setLoading(false);
                setTimeout(() => {
                    Swal.fire("Error", `Delete Membership Error! `, "error");
                }, 600);
            }
        }
    };

    const resendInviteMember = async (members: Array<any>) => {
        //Clickousite
        document.getElementsByTagName("body")[0].click();
        const result = await Swal.fire({
            showClass: {
                popup: `
                  invite_memebership_popup
                `,
            },
            title: '<div style="font-size: 24px;styleName: titlepopup;font-family: GRIFTER;font-size: 24px;font-weight: 700;line-height: 24.48px;letter-spacing: 0.01em;">RESEND INVITE<div>',
            html: `<div style="color: #161616;font-size: 16px;">The invited member will receive a new access link. Your old invite will be invalidated and cannot be accessed.</div><div class="emailField">${members[0].rowData.email}</div>`,
            showCancelButton: false,
            confirmButtonText: "send",
        });
        if (!result.isConfirmed) {
            return;
        }
        setLoading(true);
        try {
            await gameServerApi.membership.inviteMembership({
                orgId: membershipId,
                email: members[0].rowData.email,
            });
            setLoading(false);
            await Swal.fire(
                "Success",
                `Invitation resend to: <span><b>${members[0].rowData.email}</b></span>.`,
                "success"
            );
            await resetMemberList();
        } catch (error) {
            setTimeout(() => {
                Swal.fire(
                    "Error",
                    error?.response?.data?.error?.message ||
                    "Resend Member Error! ",
                    "error"
                );
            }, 600);
            setLoading(false);
        }
    };

    useEffect(() => {
        let urlParams = `?search=${queryParameter.search}`;
        if (queryParameter?.page) {
            urlParams += `&page=${queryParameter.page}`;
        }
        if (queryParameter?.perPage) {
            urlParams += `&perPage=${queryParameter.perPage}`;
        }
        history.replace(urlParams);
        getInvitedMembership(membershipId, queryParameter?.search, {
            page: queryParameter?.page,
            perPage: queryParameter?.perPage,
        });
    }, [queryParameter]);

    useEffect(() => {
        getMembershipDetail();
    }, []);

    const propsTable: IMembershipTable = {
        dataTable: {
            column: columnTable,
            rows: formatInviteMemberRows(invitedMembership.rows),
            actions: [
                { name: "resend", action: resendInviteMember },
                { name: "remove", action: deleteMember },
            ],
            deleteMultiAction: deleteMultiMember,
            loading: loading,
            pagination: {
                totalRows: invitedMembership.total,
                rowsPerPage: queryParameter.perPage,
                handleChangePage: handleChangePage,
                currentPage: queryParameter.page,
            },
        },
    };

    return {
        loading,
        propsTable,
        membershipId,
        handleSearch,
        refetchInvitedMembership: resetMemberList,
        currentMembership,
        emptyPage,
    };
};
