import {
    Backdrop,
    Box,
    Button,
    IconButton,   
    Modal,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./style.scss";
import Close from "@mui/icons-material/Close";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const CancelMembershipDurringOfferNoticeModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
    onCancel: () => void;
}> = ({ open, onClose, onCancel }) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
           
        >
            <div className="cancel-membership-notice-modal-component">
                <IconButton onClick={onClose} className="close-button">
                    <Close></Close>
                </IconButton>
                <Box>
                    <img src="/images/icons/membership-alert.svg" alt='icon' />
                    <Typography component="h3" variant="h3" mb="8px" mt="56px">
                        ARE YOU SURE YOU WANT TO CANCEL
                    </Typography>
                    <Typography
                        component="p"
                        variant="body1"
                        mb="8px"
                        fontSize={"16px"}
                    >
                        <ul>
                            <li>
                                Your account will be canceled immediately and
                                you will no longer be charged going forward.
                            </li>
                            <li>
                                You will no longer have access to monthly
                                challenges, leaderboards & the Aviron exclusive
                                content.
                            </li>
                            <li>
                                You will miss out on our ever-growing library
                                that come with your membership.
                            </li>
                        </ul>
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    onClick={onClose}
                    color="secondary"
                    fullWidth
                    sx={{ marginTop: "24px", marginBottom: "24px" }}
                >
                    CLOSE
                </Button>
                <br />
                <Typography variant="button" fontSize={"16px"}>
                    <u
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={onCancel}
                    >
                        Cancel membership
                    </u>
                </Typography>
            </div>
        </Modal>
    );
};

export default CancelMembershipDurringOfferNoticeModal;
