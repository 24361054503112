import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DataTable from "Components/DataTable";
import { AvironLoading } from "Components/Icons/AvironLoading";
import PageTitle from "Components/PageTitle";
import { CONSTANTS } from "consts";
import get from "lodash/get";
import moment from "moment";
import { default as React, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ENV } from "scripts/environment";
import { mobileDetect } from "scripts/mobileDetect";
import { capitalizeFirstLetter } from "scripts/utils/string";
// import MembershipCard from './MembershipCard';
import { Stack } from "@mui/material";
import loadable from "@react-loadable/revised";
import { Helmet } from "react-helmet";
import {
    useAvironSubscriptionsQuery,
    useSharedQuery,
} from "services/webServer";
import { modelColors } from "../membership.constant";
import {
    RenderEmptyDataMembershipWithC2,
    useQueryMembershipHistoryListC2
} from "./MembershipsMain.actions";
import {
    membershipsColumnsForAPK,
    getStatus,
} from "./MembershipsMain.constant";
import "./style.scss";
import { getUrlVars } from "utils/url";
import ReactiveMembershipNoticeModal from "./ReactiveMembershipNotice";
import ReactiveMembershipNoticeWithoutPromoModal from "./ReactiveMembershipNoticeWithoutPromo";
import AppChip from "Components/AppChip";
import { formatAppIds } from "utils/membership";


const MembershipCard = loadable({
    loader() {
        return import("./MembershipCard");
    },
    loading: AvironLoading,
});

const initModalState = {
    openReactiveNotice: false,
    openReactiveNoticeWithoutPromo: false,
};

const stateColors = [
    { id: "inactive", value: "Not Activated", color: "#212EA9" },
    { id: "active", value: "Activated", color: "#16C231" },
    { id: "past_due", value: "Past Due", color: "#B8270C" },
    { id: "canceled", value: "Canceled", color: "#949494" },
    { id: "ended", value: "Ended", color: "#6C0074" },
    { id: "pause_collection", value: "Pause Payment", color: "#F58C0F" },
];

export default function MembershipMain() {

    const [modalState, setModalState] = React.useState(initModalState);
    const [loading, setLoading] = useState(false);

    const resetModalState = () => {
        setModalState(initModalState);
    };

    useEffect(() => {
        const queryString = getUrlVars();
        const reactiveSource = sessionStorage.getItem("reactiveSource");
        if(reactiveSource && queryString.source && queryString.source !== reactiveSource){
            sessionStorage.removeItem("reactive")
        }
        if (queryString.source && (queryString.source === "qr-code" || queryString.source === "klaviyo-email")) {
            sessionStorage.removeItem("reactiveWithoutPromo");
            sessionStorage.setItem("reactive", "true");
            sessionStorage.setItem("reactiveSource", queryString.source);
            const isShowNoticeReactive = sessionStorage.getItem("isShowNoticeReactive");
            if (!isShowNoticeReactive) {
                setModalState({ ...initModalState, openReactiveNotice: true })
            }
        } else if (queryString.source && queryString.source === "qr-code-WTHPR24") {
            sessionStorage.removeItem("reactive");
            sessionStorage.setItem("reactiveWithoutPromo", "true");
            sessionStorage.setItem("reactiveSource", queryString.source);
            const isShowNoticeReactive = sessionStorage.getItem("isShowNoticeReactiveWithoutPromo");
            if (!isShowNoticeReactive) {
                setModalState({ ...initModalState, openReactiveNoticeWithoutPromo: true })
            }
        }
    }, [])

    const handleCloseNoticeReactiveModal = () => {
        // console.log("vo day")
        sessionStorage.setItem("isShowNoticeReactive", "true");
        resetModalState()
    }

    const handleCloseNoticeReactiveWithoutPromoModal = () => {
        sessionStorage.setItem("isShowNoticeReactiveWithoutPromo", "true");
        resetModalState()
    }

    const [activeTab, setActiveTab] = useState(0);
    // Show data for c2
    const appId = localStorage.getItem("appId");
    const isC2App = React.useMemo(() => {
        if (typeof appId === "string") {
            return (
                [
                    CONSTANTS.APP.C2_ROWER,
                    CONSTANTS.APP.C2_SKIERG,
                    CONSTANTS.APP.C2_BIKEERG,
                    CONSTANTS.APP.WATER_ROWER,
                ].indexOf(Number(appId)) !== -1
            );
        }
        return false;
    }, [appId]);

    const isAPK = !isC2App;
    const columns = membershipsColumnsForAPK;

    const history = useHistory();
    const isMobile = mobileDetect();

    const [membershipsList, setMembershipsList] = React.useState<
        undefined | any[]
    >(undefined);

    const { handleQueryData } = useQueryMembershipHistoryListC2();
    // const { handleQueryDataPlan } = useQueryPlanListForC2();

    const handClickDetail = (id: string, appType: "apple" | "apk") => {
        if(appType === "apple"){
            history.push(
                `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_APPLE}/${id}`
            );
        } else {
            history.push(`${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${id}`);
        }
    };

    const getModelCell = (appIds: number[]) => {
        return (<div className="model-chip-wrapper">
            {appIds.map(appId => {
                return <AppChip appId={appId} />
            })}
        </div>)
    }

    useEffect(() => {
        setLoading(true)
            handleQueryData()
                .then(async (res) => {
                    const result = res?.data?.memberships || [];

                const rows = result.map((item: any, index: number) => {
                    const status = getStatus(
                        item?.status?.toString(),
                        item?.isPauseCollection
                    );
                    const isCanceled = status?.name.includes("Canceled");

                    return {
                        id: item.orgId,
                        appType: item.paymentMethod,
                        status: (
                            <p
                                style={{
                                    color: status?.color,
                                    cursor: "pointer",
                                }}
                                onClick={() => { handClickDetail(item.orgId, item?.paymentMethod) }}
                            >
                                {status?.name}
                            </p>
                        ),
                        name: item?.planName || '---',
                        model: getModelCell(formatAppIds(item?.appIds)),
                        startAt: item?.purchaseAt
                                    ? moment
                                        .unix(item.purchaseAt)
                                        .format("MM/DD/YYYY")
                                    : "---",
                            nextBillingDate: isCanceled
                                ? "---"
                                : item?.expireAt
                                    ? moment
                                        .unix(item.expireAt)
                                        .format("MM/DD/YYYY")
                                    : "---",
                            token: item?.orgKey || '---',
                            appIds: formatAppIds(item?.appIds),
                            // mToken: "---",
                        };
                    });
                    setMembershipsList(rows);
                    setLoading(false);
                })
                .catch(() => {
                    setMembershipsList([]);
                    setLoading(false);
                });

    }, []);

    const handleSelectApp = (appId: number) => {
        const domTarget = document.getElementById(
            `select-app-${appId}`
        );
        if (domTarget && window.innerWidth < 900) {
            domTarget.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
            });
        }
        setActiveTab(appId);
    };

    const currentListMembership = useMemo(() => {
        if (membershipsList === undefined) {
            return membershipsList
        }
        return membershipsList.filter((membership => {
            if (activeTab === 0) {
                return true;
            }
            return membership?.appIds?.includes(activeTab)
        }))
    }, [membershipsList, activeTab])

    if (loading || membershipsList === undefined || currentListMembership === undefined) {
        return (
            <div className="height-loading">
                <AvironLoading />
            </div>
        ) 
       
    }
    if (!loading && membershipsList?.length === 0 && !isAPK) {
        return RenderEmptyDataMembershipWithC2();
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Memberships | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ReactiveMembershipNoticeModal open={modalState.openReactiveNotice} onClose={handleCloseNoticeReactiveModal} />
            <ReactiveMembershipNoticeWithoutPromoModal open={modalState.openReactiveNoticeWithoutPromo} onClose={handleCloseNoticeReactiveWithoutPromoModal} />
            <Box
                sx={{
                    marginBottom: "16px",
                }}
            >
                <PageTitle>Memberships</PageTitle>
            </Box>
            {!isC2App && (
                <Box width={"100%"}>
                    <Stack
                        direction="row"
                        spacing={{
                            xs: "8px",
                            md: "8px",
                        }}
                        mb="16px"
                        overflow="auto"
                        display="flex"
                        sx={{
                            flexWrap: {
                                xs: "nowrap",
                                md: "wrap",
                            },
                            justifyContent: {
                                xs: "space-between",
                                md: "start",
                            },
                            paddingBottom: {
                                xs: "4px",
                                md: 0,
                            },
                            "::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                        className={"select-app-tab"}
                    >
                        <div>
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleSelectApp.bind(null, 0)}
                                className={activeTab === 0 ? "tab--active" : ""}
                                id={`select-app-0`}
                            >
                                ALL
                            </Button>
                        </div>
                        <div>
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleSelectApp.bind(null, 1)}
                                className={activeTab === 1 ? "tab--active" : ""}
                                id={`select-app-1`}
                            >
                                WITH AVIRON TOUCHSCREEN
                            </Button>
                        </div>
                        <div>
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleSelectApp.bind(null, 16)}
                                className={activeTab === 16 ? "tab--active" : ""}
                                id={`select-app-16`}
                            >
                                WITH PERSONAL TABLET
                            </Button>
                        </div>
                    </Stack>
                </Box >
            )
            }
            {
                (!loading && currentListMembership.length === 0) && (
                    <Box
                        sx={{
                            width: "fit-content",
                            margin: "64px auto",
                            textAlign: "center",
                        }}
                    >
                        <img src="/images/icons/document.svg" />
                        <Typography mt="24px" mb="16px" variant="h4" component="h4">
                            You don't have any memberships yet.
                        </Typography>
                        {(activeTab !== CONSTANTS.APP.AVIRON_GO) && (
                            <>
                                <Typography mb="16px" variant="body1" component="p">
                                    You can go to shop page to purchase a
                                    membership.
                                </Typography>
                                <a
                                    href={`${ENV.STOREFRONT_DOMAIN}/shop/home-fitness/personal-membership/`}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ width: "167px" }}
                                    >
                                        SHOP NOW
                                    </Button>
                                </a>
                            </>
                        )}
                    </Box>
                )
            }
            {
                !isMobile ? (
                    <Box>
                        {currentListMembership.length > 0 &&
                            <Box marginTop={"32px"}>
                                <DataTable
                                    collums={columns}
                                    rows={currentListMembership}
                                    colors={stateColors}
                                    colorField="state"
                                />
                            </Box>}
                    </Box>
                ) : (
                    <Box>
                        <Box marginTop={"32px"}>
                            {/* <Typography component="h4" variant="h5">AVIRON MEMBERSHIP</Typography> */}
                            {currentListMembership?.map((row: any) => {
                                return (
                                    <MembershipCard
                                        {...row}
                                        stateColors={stateColors}
                                        onClickDetail={handClickDetail}
                                    />

                                );
                            })}
                        </Box>
                    </Box>
                )
            }
        </>
    );
}
