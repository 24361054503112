const API_DOMAIN = "http://localhost:4040/";
const ROOT_FILE = "";
const CONSTANTS = {
    NOTIFY_TYPES: {
        SUCCESS: "success",
        ERROR: "error",
        INFO: "info",
        WARNING: "warning",
    },
    STORAGE_NAME: {
        ACCESS_TOKEN_KEY_NAME: "ACCESS_TOKEN",
        USER_INFO: "USER_INFO",
    },
    INPUT_LENGTH: {
        PASSWORD_MIN: 6,
    },
    NAVIGATION_URL: {
        ROOT_FILE: "",
        HOME: `/`,
        NOT_FOUND: `/page_not_found`,
        PROFILE: `/profile/`,
        WORKOUT_HISTORY: `/workout_history/`,
        WORKOUT_HISTORY_DETAIL: `/workout_history/detail`,
        WORKOUT_HISTORY_COMPARE_PERIOD: `/workout_history/compare_period`,
        WORKOUT_HISTORY_ACHIEVEMENTS: `/workout_history/achievements`,
        MEMBERSHIPS: `/membership`,
        MEMBERSHIP_DETAIL_OLD: `/membership/variation/:id`,
        MEMBERSHIP_DETAIL: `/membership/:id`,
        MEMBERSHIP_APPLE: `/membership/apple`,
        MEMBERSHIP_DETAIL_APPLE: `/membership/apple/:id`,
        MEMBERSHIP_INVITE_LIST: `/membership/invite_list/:id`,
        MEMBERSHIP_FAMILLY: `/membership/family/:id`,
        MEMBERSHIP_INVITE_CONFIRM: `/membership/accept-invitation`,
        MEMBERSHIP_PAUSE_OR_CANCEL: `/membership/pause-or-cancel`,
        MEMBERSHIP_CANCEL_REASONS: `/membership/cancel-reasons`,
        MEMBERSHIP_CANCELLATION_OFFER: `/membership/cancellation-offer`,
        MEMBERSHIP_REVIEW_AND_CANCEL: `/membership/review-and-cancel`,
        MEMBERSHIP_REDEEM_OFFER: `/membership/redeem-offer`,
        MEMBERSHIP_MACHINE_MANAGE: `/membership/machine-manage/:id`,
        ORDERS: `/order`,
        ORDER_DETAIL: `/order/:id`,
        PAYMENT_METHOD: `/payment_method`,
        INTEGRATIONS: `/integrations`,
        LOGIN: `/login`,
        FORGOT_PASSWORD: `/forgot_password`,
        STRAVA: `/strava`,
        LOGBOOK: `/logbook`,
        BETA_CODE: `/beta_code`,
        CHANGE_EMAIL_CONFIRM: `/profile/change_email_confirm`,
        FITBIT:`/fitbit`,
    },
    REDIRECT_URL: {
        PROFILE_BASIC_INFO: '/profile/basicinfo',
        SUBSCRIPTION_OVERVIEW: '/subscription/overview',
        SUBSCRIPTION_RECEIPT: '/subscription/receipt',
        SUBSCRIPTION_BILLING_INFO: '/subscription/billing/info',
    },
    SIDEBAR_PAGEID: {
        NONE: -1,
        PROFILE: 100,
        WORKOUT_HISTORY: 200,
        MEMBERSHIPS: 300,
        ORDERS: 400,
        PAYMENT_METHOD: 500,
        INTEGRATIONS: 600,
    },
    TRACKING_STATUS: {
        UN_DONE: "UN_DONE",
        IN_PROGRESS: "IN_PROGRESS",
        DONE: "DONE",
    },
    SUBSCRIPTION_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            SUBSCRIPTION_OVERVIEW: 0,
            SUBSCRIPTION_RECEIPT: 1,
            SUBSCRIPTION_BILLING: 2,
        },

        PERIOD_TIME: {
            MONTH_1: "1",
            MONTHS_3: "3",
            MONTHS_6: "6",
            MONTHS_12: "12",
        },
        SUBSCRIPTION_TYPE: {
            PERSONAL: "personal",
            COMMERCIAL: "commercial",
        },
    },
    PROFILE_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            PROFILE_OVERVIEW: 0,
            PROFILE_WORKOUT: 1,
            PROFILE_ACHIEVEMENT: 2,
            PROFILE_BASICINFO: 3,
        },
        TRIGGER_INTERVAL: {
            MIN: 0,
            MAX: 9999,
        },
        GENDER: {
            MALE: 2,
            FEMALE: 1,
            OTHER: 0,
        },
        // HEIGHT_UNITS: {
        //     IN: 'in',
        //     METERS: 'meters',
        //     CM: "cm"
        // },
        // WEIGHT_UNITS: {
        //     LBS: 'lbs',
        //     KGS: 'kgs'
        // },
        HEIGHT_UNITS: {
            IN: 0,
            CM: 1,
            // CM: "cm"
        },
        WEIGHT_UNITS: {
            LBS: 0,
            KGS: 1,
        },
        MAXXIMUM_MB_IMAGE_SUPPORT: 2,
        SRC_NO_AVATAR: "/images/user/no-avatar.png",
    },
    EQUIPMENT_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            EQUIPMENT_OVERVIEW: 0,
            EQUIPMENT_ANALYSIS: 1,
        },
    },
    RECEIPT_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            RECEIPT: 0,
            RECEIPT_DETAIL: 1,
        },
    },
    PRODUCT_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            PRODUCT_IMPACT: 0,
            PRODUCT_TOUGH: 1,
        },
    },
    MEMBERSHIP_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            PERSONAL: 0,
            COMMERCIAL: 1,
        },
    },
    CHECKOUT_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            CHECKOUT_INFORMATION: 0,
            CHECKOUT_PAYMENT_METHOD: 1,
            CHECKOUT_REVIEW_ORDER: 2,
        },
    },
    SESSION_NAME: {
        ACCESS_TOKEN: "ACCESS_TOKEN",
        USER_INFO: "USER_INFO",
    },
    FORMAT_DATE: "mm/dd/yyyy",
    MESSAGE_BOX_TYPE: {
        ERROR: 1,
        WARNING: 2,
        INFO: 3,
        QUESTION: 4,
        SUCCESS: 5,
    },
    MESSAGE_BOX_BUTTON_TYPE: {
        ONE_BUTTON: 1,
        TWO_BUTTON: 2,
        THREE_BUTTON: 3,
        NONE_BUTTON: 4,
    },
    NOTIFY_USER: {},
    SERVER_ERROR: {
        GENERAL: {
            BAD_REQUEST: "bad-request",
        },
        EMAIL: {
            IS_NOT_VERIFIED: "isNotVerified",
        },
    },
    GAME_TYPE: {
        kGameTypeWarmUp: 0,
        kGameTypeLightningLap: 1,
        kGameTypeHeadToHead: 2,
        kGameTypeGrandprix: 3,
        kGameTypeZombieLastHope: 4,
        kGameTypeZombieTheHunted: 5,
        kGameTypeZombieApocalypse: 6,
        kGameTypeVirtualWorkout: 7,
        kGameTypeMetricMonitor: 8,
        kGameTypeWorkoutProgram: 9,
        kGameTypeAthleteBattle: 10,
        kGameTypeKraken: 11,
        kGameTypeKrakenRace: 12,
        kGameTypeKrakenUnited: 13,
        kGameTypeVideo: 14,
        kGameTypeBlaster: 15,
        kGameTypeKnockout: 16,
        kGameTypePacer: 17,
        kGameTypeTargetPractice: 18,
        kGameTypePong: 19,
        kGameTypePong2: 20,
        kGameTypeBrickBreaker: 21,
        kGameTypeYoutube: 22,
        kGameTypeBlasterMultiPlayer: 23,
        kGameTypeRacingProgram: 24,
        kGameTypeStunami: 25,
        kGameTypeMonsterShooting: 26,
        kGameTypeSnowShooting: 27,
        kGameTypeCoachedProgram: 28,
        kGameTypeStreaming: 29,
        kGameTypeNum: 30,
    },
    TIME_TYPE: {
        ALL: "all",
        DAY: "day",
        WEEK: "week",
        MONTH: "month",
    },
    METRIC_TYPE: {
        OUTPUTS: "outputs",
        AVG_WATTS: "avgWatts",
        CALORIES: "calories",
        AVG_CALORIES: "avgCalories",
        METERS: "meters",
        AVG_MIN500S: "avgMin500s",
        AVG_SPMS: "avgSpms",
    },
    STATUS_TYPE: {
        ACTIVE: "active",
        INACTIVE: "inactive",
        CANCELLED: "canceled",
        ENDED: "ended",
    },
    WORKOUT_QUERY_RANGE: {
        QUERY_RANGE_ALL: 0,
        QUERY_RANGE_LAST_24H: 1,
        QUERY_RANGE_ALL_LAST_7DAYS: 2,
        QUERY_RANGE_ALL_LAST_30DAYS: 3,
    },
    WORKOUT_PARAMETER: {
        kDIndexKiloJoules: 0,
        kDIndexWatt: 1,
        kDIndexCalories: 2,
        kDIndexCalPerHour: 3,
        kDIndexMeters: 4,
        kDIndexSecPer500: 5,
        kDIndexSPM: 6,
        kDIndexHeartRate: 7,
        kDIndexKiloJoulesPerStroke: 8,
        kDIndexNum: 9,
    },
    WORKOUT_HISTORY_LIMIT: 10,
    SERVER: {
        VENDURE: "vendure",
        CRM_SERVER: "crm-server",
    },
    TIME_RANGE_ID: {
        today: 1,
        day7: 2,
        day30: 3,
        day90: 4,
        day180: 5,
        day365: 6,
    },
    SWITCH_MACHINE: {
        apk: {
            id: 1,
            group: 'apk'
        },
        c2: {
            id: 2,
            group: 'c2'
        },
        c2Bike: {
            id: 8,
            group: 'c2'
        },
        go: {
            id: 16,
            group: 'apk'
        },
        bike: {
            id: 128,
            group: 'bike'
        }
    },
    APP: {
        AVIRON_APK:1,
        C2_ROWER: 2,
        C2_SKIERG: 4,
        C2_BIKEERG: 8,
        AVIRON_GO: 16,
        WATER_ROWER: 64,
        MEMBERSHIP_C2: 14,
        MEMBERSHIP_GO: 16,
        AVIRON_BIKE: 128,
        BIKE_GO: 256,
    },
    UNIT_CONVERT: {
        MILE_TO_METER : 1609.344,
        MILE_TO_KILOMETER: 1.609344,
        KILOMETER_TO_METER: 1000,
        KILOMETER_CONVERT_TO_MILE_VALUE: 0.6213711922,
    }
};

export { API_DOMAIN, ROOT_FILE, CONSTANTS };
